//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import i18n from '@/vueI18n';
import firebase from 'firebase';
import 'firebase/firestore'
import Message from '@/shared/message/message';
import {routerAsync} from '@/app-module';
const { fields } = UserModel;

export default {
  name: 'app-signin-page',
  data() {
    return {
      obj: null,
      signInLoader:false,
      results: null,
      cc: null,
      pn: '',
      step: 1,
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
        rememberMe: fields.rememberMe.forFormRules(),
      },
      model: {
        rememberMe: true,
        phoneNumber: '',
        code: ''
      },
      isPwd: true,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      is_screen_xs: 'layout/is_screen_xs',
      is_labtop: 'layout/is_labtop',
    }),
    validPn(){
      if(this.cc && this.cc !== 'EG') return true
      if(this.cc && this.cc === 'EG' && this.pn.length === 10){
        return true
      }
      return false
    },
    fields() {
      return fields;
    },
    getProgress() {
      let progress = 0

      // at least one number
      if (/\d/.test(this.model.password)) {
        progress += 20
      }

      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.model.password)) {
        progress += 20
      }

      // at menons a lowercase
      if (/(.*[a-z].*)/.test(this.model.password)) {
        progress += 20
      }

      // more than 5 digits
      if (this.model.password.length >= 6) {
        progress += 20
      }

      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.model.password)) {
        progress += 20
      }

      return progress
    },
    language(){
      return i18n.locale
    }
  },
  mounted(){
    window.recaptchaVerifier =  new firebase.auth.RecaptchaVerifier('recaptcha-div', {
      'size': 'invisible',
    });
  },
  watch:{
    language(){
      document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('auth.signin') 
    }
  },
  methods: {
    ...mapActions({
      doSigninSocial: 'auth/doSigninSocial',
      doSigninWithPhoneNumber:
        'auth/doSigninWithPhoneNumber',
        doSigninWithPhoneNumberCredential:'auth/doSigninWithPhoneNumberCredential',
    }),
    doSignout(){
      routerAsync().push('/');
    },
    onUpdate (payload) {
      console.log(payload);
      this.cc = payload.countryCode
      this.pn = payload.nationalNumber ? payload.nationalNumber.trim() : ''
      this.model.phoneNumber = payload.formattedNumber
    },
    isPhoneNumberValid(phoneNumber){
      return /^\+?[0-9]\d{1,14}$/.test(phoneNumber)
    },
    handleOnCompleteVerifiy(value) {
      if (this.hasNumber(value)) {
        this.model.code = value
        this.error = '';
      } else {
        this.error = i18n('auth.error.verificationCodeShouldOnlyBeNumbers');
        this.model.code = ''
      }
    },
    handleOnChangeVerifiy(value){
      if (value.length > 0) {
        this.error = '';
      }
      if (value.length < 8) {
        this.model.code = '';
      }
    },
    hasNumber(myString) {
      return /^\d+$/.test(myString);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      this.signInLoader = true
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      console.log(this.model.phoneNumber);
      if(this.step === 1){
          return this.sendMessage(() => {
            this.signInLoader = false
            this.step = 2
          })
          // return this.signInLoader = false
          
      }
      if(this.step === 2){
        await this.doSigninWithPhoneNumberCredential({ verificationId:this.user, code:this.model.code, rememberMe:true })
        return this.signInLoader = false
        // this.doSigninWithPhoneNumber({code:this.model.code,user:this.user})
      }
      // await this.doSigninWithEmailAndPassword({
      //   email: this.model.email,
      //   password: this.model.password,
      //   rememberMe: this.model.rememberMe,
      // });
    },
    sendMessage(cb){
        let appVerifier = window.recaptchaVerifier;
          firebase.auth().signInWithPhoneNumber(this.model.phoneNumber,appVerifier).then(user => {
            this.user = user
            cb?cb():''
          }).catch(err => {
            Message.error(i18n('firebaseErrors.toManyTries'))
            console.log(err);
          })
    }
  },
};
